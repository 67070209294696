<template>
  <div class="wrapper">
    <Header :goodsName="goodsName" :headerData="{name: undefined}" />
    <CloudAndAccess :isVR="true" :merchantListData="videoData" :total="total" @emitChange="handleChange" />
    <Footer />
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import Header from '../../components/Header'
import CloudAndAccess from '../../components/CloudAndAccess'
import Footer from '../../components/Footer'
import { get_vr_access, IMAGE_URL } from '../../api'
import { useRouter } from 'vue-router'
// import videoMP4 from '../../assets/video/11.mp4'
export default defineComponent({
  components: {
    Header,
    Footer,
    CloudAndAccess,
  },
  data() {
    return {
      merchantListData: [],
      total: 0,
      videoData: [
        {
          id: 1,
          image:
            'https://nonggaohui-2021-ba.oss-cn-chengdu.aliyuncs.com/video/fengmian/henan.png',
          name: '河南展团',
          video_file:
            'https://nonggaohui-2021-ba.oss-cn-chengdu.aliyuncs.com/video/henan.mp4',
        },
        {
          id: 2,
          image:
            'https://nonggaohui-2021-ba.oss-cn-chengdu.aliyuncs.com/video/fengmian/nongyedaxue.png',
          name: '西北农林科技大学',
          video_file:
            'https://nonggaohui-2021-ba.oss-cn-chengdu.aliyuncs.com/video/xibeinongyedaxue.mp4',
        },
      ],
    }
  },
  mounted() {
    // this.getMerchantList()
  },
  methods: {
    async getMerchantList(page = 1) {
      this.merchantListData = videoData
      // const {
      //   data: { code, data },
      // } = await get_vr_access({ limit: 10, page })
      // console.log('data :>> ', data)
      // this.total = data.total
      // this.merchantListData = data.data.map((item) => ({
      //   ...item,
      //   image: IMAGE_URL + item.cover,
      //   video_file: videoMP4,
      // }))
      // if (code == 1) {
      //   this.total = data.total
      //   this.merchantListData = data.data.map((item) => ({
      //     ...item,
      //     image: IMAGE_URL + item.image,
      //     images: IMAGE_URL + item.images,
      //     logo: IMAGE_URL + item.logo,
      //     yyzz_images: IMAGE_URL + item.yyzz_images,
      //   }))
      // } else {
      //   message.error('数据获取失败, 请重试')
      // }
    },
    handleChange(index) {
      window.scrollTo(0, 500)
      this.getMerchantList(index)
    },
  },
})
</script>

<style lang="less" scoped>
.wrapper {
  width: 100%;
  background: #fafafa;
}
</style>
